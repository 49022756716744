// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer{
    background-color: #DAA9CF;
    display: flex;
    align-items: baseline;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    width: 100%;
    justify-content: space-around;
}

.footer_contact{
    display: flex;
    gap: 1rem;
    align-items: center;
}
.footer_info_main{
    display: flex;
}
.footer_info_3 div {
    padding-bottom: 10px;
}
.name{
    background-color: #DAA9CF;
    display: flex;
    justify-content: center;
}


.footer_info_4{
    display: flex;
    flex-direction: column;
}
.footer_link{
    padding-bottom: 10px;
}

@media (max-width: 600px) {
    .footer{
      flex-direction: column;
      justify-content: center;
    }
    .footer_info_2,
    .footer_info_3,
    .footer_info_1{
        padding-left: 4rem;
    }
    .footer_info_2{
        padding-bottom: 10px;
    }
    .footer_info_3,
    .footer_info_2{
        margin-top: 1rem;
    }
    .footer_info_2_1{
        padding-bottom: 30px !important;
    }
    .footer_info_3_1{
        padding-bottom: 30px !important;
    }
  }`, "",{"version":3,"sources":["webpack://./src/Footer/footer.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,aAAa;IACb,qBAAqB;IACrB,mBAAmB;IACnB,sBAAsB;IACtB,WAAW;IACX,6BAA6B;AACjC;;AAEA;IACI,aAAa;IACb,SAAS;IACT,mBAAmB;AACvB;AACA;IACI,aAAa;AACjB;AACA;IACI,oBAAoB;AACxB;AACA;IACI,yBAAyB;IACzB,aAAa;IACb,uBAAuB;AAC3B;;;AAGA;IACI,aAAa;IACb,sBAAsB;AAC1B;AACA;IACI,oBAAoB;AACxB;;AAEA;IACI;MACE,sBAAsB;MACtB,uBAAuB;IACzB;IACA;;;QAGI,kBAAkB;IACtB;IACA;QACI,oBAAoB;IACxB;IACA;;QAEI,gBAAgB;IACpB;IACA;QACI,+BAA+B;IACnC;IACA;QACI,+BAA+B;IACnC;EACF","sourcesContent":[".footer{\n    background-color: #DAA9CF;\n    display: flex;\n    align-items: baseline;\n    padding-top: 2.5rem;\n    padding-bottom: 2.5rem;\n    width: 100%;\n    justify-content: space-around;\n}\n\n.footer_contact{\n    display: flex;\n    gap: 1rem;\n    align-items: center;\n}\n.footer_info_main{\n    display: flex;\n}\n.footer_info_3 div {\n    padding-bottom: 10px;\n}\n.name{\n    background-color: #DAA9CF;\n    display: flex;\n    justify-content: center;\n}\n\n\n.footer_info_4{\n    display: flex;\n    flex-direction: column;\n}\n.footer_link{\n    padding-bottom: 10px;\n}\n\n@media (max-width: 600px) {\n    .footer{\n      flex-direction: column;\n      justify-content: center;\n    }\n    .footer_info_2,\n    .footer_info_3,\n    .footer_info_1{\n        padding-left: 4rem;\n    }\n    .footer_info_2{\n        padding-bottom: 10px;\n    }\n    .footer_info_3,\n    .footer_info_2{\n        margin-top: 1rem;\n    }\n    .footer_info_2_1{\n        padding-bottom: 30px !important;\n    }\n    .footer_info_3_1{\n        padding-bottom: 30px !important;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
