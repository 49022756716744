// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.offer_name{
    color: #7A2031;
    display: flex;
    justify-content: center;
}

.offer_name_main{
    display: flex;
    justify-content: center;
    font-weight: 700;
}
`, "",{"version":3,"sources":["webpack://./src/Offer/Offer.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,gBAAgB;AACpB","sourcesContent":[".offer_name{\n    color: #7A2031;\n    display: flex;\n    justify-content: center;\n}\n\n.offer_name_main{\n    display: flex;\n    justify-content: center;\n    font-weight: 700;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
