import  React from 'react';
import Box from '@mui/material/Box';
import './Policy.css'

function Pollicy() {
 

  return (
    <Box className='confidentiality' >
        <Box className='confidentiality_name' >ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</Box>
            <Box className='confidentiality_info_1_text'> 
            <p>Настоящий документ "Политика конфиденциальности" (далее по тексту – "Политика") представляет собой правила использования сайтом coucou.com [ИП Войтюк Никита Валерьевич] (далее – Оператор) персональной информации Пользователя, которую Оператор, включая всех лиц, входящих в одну группу с Оператором, могут получить о Пользователе во время использования им любого из сайтов, сервисов, служб, программ, продуктов или услуг Оператора (далее – Сайт) и в ходе исполнения Оператором любых соглашений и договоров с Пользователем. Согласие Пользователя с Политикой, выраженное им в рамках отношений с одним из перечисленных лиц, распространяется на все остальные перечисленные лица.</p>
            <p>Использование Сайта означает безоговорочное согласие Пользователя с настоящей Политикой и указанными в ней условиями обработки его персональной информации; в случае несогласия с этими условиями Пользователь должен воздержаться от использования Сайта.</p>
            <p className='confidentiality_name_main'>1. Общие положения политики</p>
            <p>1.1. Настоящая Политика конфиденциальности является неотъемлемой частью Публичной оферты (далее – "Оферта"), размещенной и/или доступной в сети Интернет по адресу: https://couou.com/page/oferta, а также иных заключаемых с Пользователем договоров, когда это прямо предусмотрено их условиями.</p>
            <p>1.2. Настоящая Политика составлена в соответствии с Федеральным законом "О персональных данных" № 152-ФЗ от 27 июля 2006 г., а также иными нормативно-правовыми актами Российской Федерации в области защиты и обработки персональных данных и действует в отношении всех персональных данных, которые Оператор может получить от Пользователя, являющегося стороной по гражданско-правовому договору.</p>
            <p>1.3. Оператор имеет право вносить изменения в настоящую Политику. При внесении изменений в заголовке Политики указывается дата последнего обновления редакции. Новая редакция Политики вступает в силу с момента ее размещения на сайте, если иное не предусмотрено новой редакцией Политики.</p>
            <p>1.4. К настоящей Политике, включая толкование ее положений и порядок принятия, исполнения, изменения и прекращения, подлежит применению законодательство Российской Федерации.</p>
            <p className='confidentiality_name_main'>2. Персональная информация Пользователей, которую обрабатывает Сайт</p>
            <p>2.1. Под персональной информацией в настоящей Политике понимается:</p>
            <p>2.1.2. Данные, которые передаются в автоматическом режиме Сайту в процессе их использования с помощью установленного на устройстве Пользователя программного обеспечения, в том числе IP-адрес, данные файлов cookie, информация о браузере Пользователя (или иной программе, с помощью которой осуществляется доступ к Сайту), технические характеристики оборудования и программного обеспечения, используемых Пользователем, дата и время доступа к Сайту, адреса запрашиваемых страниц и иная подобная информация;</p>
            <p>2.1.3. Иная информация о Пользователе, обработка которой предусмотрена условиями использования Сайта.</p>
            <p> 2.2. Настоящая Политика применима только к информации, обрабатываемой в ходе использования Сайта. Сайт не контролирует и не несет ответственность за обработку информации сайтами третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на Сайте.</p>
            <p>2.3. Сайт не проверяет достоверность персональной информации, предоставляемой Пользователем, и не имеет возможности оценивать его дееспособность.</p>
            <p className='confidentiality_name_main'>3. Цели обработки персональной информации Пользователей</p>
            <p>3.1. Сайт собирает и хранит только ту персональную информацию, которая необходима для предоставления сервисов или исполнения соглашений и договоров с Пользователем, за исключением случаев, когда законодательством предусмотрено обязательное хранение персональной информации в течение определенного законом срока.</p>
            <p>3.2. Персональную информацию Пользователя Сайт обрабатывает в следующих целях:</p>
            <p>3.2.2. Предоставление Пользователю персонализированных сервисов и услуг, а также исполнение соглашений и договоров;</p>
            <p>3.2.3. Направление уведомлений, запросов и информации, касающихся использования Сайта, исполнения соглашений и договоров, а также обработка запросов и заявок от Пользователя;</p>
            <p>3.2.4. Улучшение качества работы Сайта, удобства его использования для Пользователя, разработка новых услуг и сервисов;</p>
            <p> 3.2.5. Таргетирование рекламных материалов;</p>
            <p>3.2.6. Проведение статистических и иных исследований, на основе обезличенных данных.</p>
            <p className='confidentiality_name_main'>4. Условия обработки персональной информации Пользователей и её передачи третьим лицам</p>
            <p>4.1. В отношении персональной информации Пользователя сохраняется ее конфиденциальность, кроме случаев добровольного предоставления Пользователем информации о себе для общего доступа неограниченному кругу лиц.</p>
            <p>4.2. Сайт вправе передать персональную информацию Пользователя третьим лицам в следующих случаях:</p>
            <p>4.3.1. Пользователь выразил согласие на такие действия;</p>
            <p>4.3.2. Передача необходима для использования Пользователем определенного сервиса либо для исполнения определенного соглашения или договора с Пользователем;</p>
            <p>4.3.3. Передача необходима для функционирования и работоспособности самого Сайта;</p>
            <p>4.3.4. Передача предусмотрена российским или иным применимым законодательством в рамках установленной законодательством процедуры;</p>
            <p>4.3.5. Такая передача происходит в рамках продажи или иной передачи бизнеса (полностью или в части), при этом к приобретателю переходят все обязательства по соблюдению условий настоящей Политики применительно к полученной им персональной информации;</p>
            <p>4.3.6. В целях обеспечения возможности защиты прав и законных интересов Сайта или третьих лиц в случаях, когда Пользователь нарушает Пользовательское соглашение Сайта, настоящую Политику, либо документы, содержащие условия использования конкретных сервисов.</p>
            <p>4.3.7. В результате обработки персональной информации Пользователя путем ее обезличивания получены обезличенные статистические данные, которые передаются третьему лицу для проведения исследований, выполнения работ или оказания услуг по поручению Сайта.</p>
            <p className='confidentiality_name_main'>5. Изменение и удаление персональной информации. Обязательное хранение данных</p>
            <p>5.1. Пользователь может в любой момент изменить (обновить, дополнить) предоставленную им персональную информацию или её часть, обратившись к Сайту по контактам в разделе 9. "Контакты".</p>
            <p>5.2. Права, предусмотренные п. 5.1. настоящей Политики могут быть ограничены в соответствии с требованиями законодательства. Например, такие ограничения могут предусматривать обязанность Сайта сохранить измененную или удаленную Пользователем информацию на срок, установленный законодательством, и передать такую информацию в соответствии с законодательно установленной процедурой государственному органу.</p>
            <p className='confidentiality_name_main'>6. Обработка персональной информации при помощи файлов Cookie и счетчиков</p>
            <p>6.1. Файлы cookie, передаваемые Сайтом оборудованию Пользователя и оборудованием Пользователя Сайту, могут использоваться Сайтом для предоставления Пользователю персонализированных сервисов, для таргетирования рекламы, которая показывается Пользователю, в статистических и исследовательских целях, а также для улучшения Сайта.</p>
            <p>6.2. Пользователь осознает, что оборудование и программное обеспечение, используемые им для посещения сайтов в сети интернет, могут обладать функцией запрещения операций с файлами cookie (для любых сайтов или для определенных сайтов), а также удаления ранее полученных файлов cookie.</p>
            <p>6.3. Яндекс вправе установить, что предоставление определенного сервиса или услуги возможно только при условии, что прием и получение файлов cookie разрешены Пользователем.</p>
            <p>6.4. Структура файла cookie, его содержание и технические параметры определяются Сайтом и могут изменяться без предварительного уведомления Пользователя.</p>

            <p>6.5. Счетчики, размещенные Сайтом, могут использоваться для анализа файлов cookie Пользователя, для сбора и обработки статистической информации об использовании Сайта, а также для обеспечения работоспособности Сайта в целом или их отдельных функций в частности. Технические параметры работы счетчиков определяются Сайтом и могут изменяться без предварительного уведомления Пользователя.</p>
            <p className='confidentiality_name_main'>7. Защита персональной информации Пользователя</p>
            <p>7.1. Сайт предпринимает необходимые и достаточные организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий с ней третьих лиц.</p>
            <p className='confidentiality_name_main'>8. Изменение Политики конфиденциальности</p>
            <p>8.1. Сайт имеет право вносить изменения в настоящую Политику конфиденциальности. При внесении изменений в актуальной редакции указывается дата последнего обновления. Новая редакция Политики вступает в силу с момента ее размещения, если иное не предусмотрено новой редакцией Политики. Действующая редакция постоянно доступна на странице по адресу https://coucou.com/page/oferta.</p>
            <p className='confidentiality_name_main'>9. Контакты и вопросы по персональным данным</p>
            <p>9.1. Все предложения, вопросы, запросы и иные обращения по поводу настоящей Политики и использования своих персональных данных Пользователь вправе направлять Сайту: по адресу электронной почты: info_coucou@mail.ru</p>

            </Box>
    </Box>
);
}

export default Pollicy;
