// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.delivery_info_1_name,
.delivery_info_2_name,
.delivery_info_3_name,
.delivery_name{
    color: #7A2031
}

.delivery_name{
    font-size: 2rem;
}
.delivery{
    padding: 9rem 2rem 2rem 3rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.delivery_info{
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.delivery_info_1_text,
.delivery_info_2_text,
.delivery_info_3_text{
    padding-left: 1rem;
    padding-top: 1rem;
    padding-right: 1rem;
}

.delivery_info_1_text p,
.delivery_info_2_text p,
.delivery_info_3_text 
{
    text-align: justify;
    margin-bottom: 0px;
}


@media (min-width: 768px) {
    .delivery_info_1_text p,
    .delivery_info_2_text p,
    .delivery_info_3_text 
    {
        font-size: 18px;
    }
  }
`, "",{"version":3,"sources":["webpack://./src/Delivery/Delivery.css"],"names":[],"mappings":"AAAA;;;;IAII;AACJ;;AAEA;IACI,eAAe;AACnB;AACA;IACI,4BAA4B;IAC5B,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;;;IAGI,kBAAkB;IAClB,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;;;;IAII,mBAAmB;IACnB,kBAAkB;AACtB;;;AAGA;IACI;;;;QAII,eAAe;IACnB;EACF","sourcesContent":[".delivery_info_1_name,\n.delivery_info_2_name,\n.delivery_info_3_name,\n.delivery_name{\n    color: #7A2031\n}\n\n.delivery_name{\n    font-size: 2rem;\n}\n.delivery{\n    padding: 9rem 2rem 2rem 3rem;\n    display: flex;\n    flex-direction: column;\n    gap: 3rem;\n}\n\n.delivery_info{\n    display: flex;\n    flex-direction: column;\n    gap: 3rem;\n}\n\n.delivery_info_1_text,\n.delivery_info_2_text,\n.delivery_info_3_text{\n    padding-left: 1rem;\n    padding-top: 1rem;\n    padding-right: 1rem;\n}\n\n.delivery_info_1_text p,\n.delivery_info_2_text p,\n.delivery_info_3_text \n{\n    text-align: justify;\n    margin-bottom: 0px;\n}\n\n\n@media (min-width: 768px) {\n    .delivery_info_1_text p,\n    .delivery_info_2_text p,\n    .delivery_info_3_text \n    {\n        font-size: 18px;\n    }\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
