// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
.header_link_all {
    display: flex;
    gap: 3rem;
    font-size: 1.5rem;
  }
.header_list{
    display: flex;
    align-items: center; 
    flex-direction: column;
    font-size: 2rem;
}

.header_main {
    z-index: 1000;
    position: fixed;
    width: 100%;
  }
/* .headerLink{
    color: black !important;
    text-decoration: none !important;
    font-family: 'Longinus', sans-serif;
} */
/* .header_logo{
position: absolute;
left: 45%;


} */
.header_basket{
    color: white;
}
.headerLinkText { 
    font-size: 1rem !important;
    font-family: 'Forum', sans-serif !important;
    color: black;
    text-decoration: none;
}
.header_main{
    background-color: white;
    
}

@media (max-width: 680px) {
    .header_logo img{
        width: 60% !important;
}
.header_logo a{
    justify-content: center;
}
}`, "",{"version":3,"sources":["webpack://./src/Header/Header.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,oBAAoB;IACpB,qBAAqB;AACzB;AACA;IACI,aAAa;IACb,SAAS;IACT,iBAAiB;EACnB;AACF;IACI,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,WAAW;EACb;AACF;;;;GAIG;AACH;;;;;GAKG;AACH;IACI,YAAY;AAChB;AACA;IACI,0BAA0B;IAC1B,2CAA2C;IAC3C,YAAY;IACZ,qBAAqB;AACzB;AACA;IACI,uBAAuB;;AAE3B;;AAEA;IACI;QACI,qBAAqB;AAC7B;AACA;IACI,uBAAuB;AAC3B;AACA","sourcesContent":["\n.header{\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding-left: 1.5rem;\n    padding-right: 1.5rem;\n}\n.header_link_all {\n    display: flex;\n    gap: 3rem;\n    font-size: 1.5rem;\n  }\n.header_list{\n    display: flex;\n    align-items: center; \n    flex-direction: column;\n    font-size: 2rem;\n}\n\n.header_main {\n    z-index: 1000;\n    position: fixed;\n    width: 100%;\n  }\n/* .headerLink{\n    color: black !important;\n    text-decoration: none !important;\n    font-family: 'Longinus', sans-serif;\n} */\n/* .header_logo{\nposition: absolute;\nleft: 45%;\n\n\n} */\n.header_basket{\n    color: white;\n}\n.headerLinkText { \n    font-size: 1rem !important;\n    font-family: 'Forum', sans-serif !important;\n    color: black;\n    text-decoration: none;\n}\n.header_main{\n    background-color: white;\n    \n}\n\n@media (max-width: 680px) {\n    .header_logo img{\n        width: 60% !important;\n}\n.header_logo a{\n    justify-content: center;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
