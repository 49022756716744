// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/Forum-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'Forum';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
   .table_style td{
    border: 1px solid gray;
    text-align: center;
   }`, "",{"version":3,"sources":["webpack://./src/Product/AccordionCopm.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,+DAAyD;IACzD,mBAAmB;IACnB,kBAAkB;EACpB;GACC;IACC,sBAAsB;IACtB,kBAAkB;GACnB","sourcesContent":["@font-face {\n    font-family: 'Forum';\n    src: url('../fonts/Forum-Regular.ttf') format('truetype');\n    font-weight: normal;\n    font-style: normal;\n  }\n   .table_style td{\n    border: 1px solid gray;\n    text-align: center;\n   }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
