// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.product-list {
    display: flex;
    gap: 4rem;
    white-space: nowrap; 
    flex-wrap: wrap;
    margin-left: 4rem;
    margin-top: 5rem;
    z-index: 1 !important; 
  }
  .button_product{
    background-color:#7A2031 !important;
    border-radius: 0 !important;
    border: none !important;
    color: #DAA9CF !important;
  }
  
  .button_product1{
    background-color:#7A2031 !important;
    border-radius: 0 !important;
    border: none !important;
    color: #DAA9CF !important;
  }`, "",{"version":3,"sources":["webpack://./src/Product/Product.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,SAAS;IACT,mBAAmB;IACnB,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,qBAAqB;EACvB;EACA;IACE,mCAAmC;IACnC,2BAA2B;IAC3B,uBAAuB;IACvB,yBAAyB;EAC3B;;EAEA;IACE,mCAAmC;IACnC,2BAA2B;IAC3B,uBAAuB;IACvB,yBAAyB;EAC3B","sourcesContent":["\n.product-list {\n    display: flex;\n    gap: 4rem;\n    white-space: nowrap; \n    flex-wrap: wrap;\n    margin-left: 4rem;\n    margin-top: 5rem;\n    z-index: 1 !important; \n  }\n  .button_product{\n    background-color:#7A2031 !important;\n    border-radius: 0 !important;\n    border: none !important;\n    color: #DAA9CF !important;\n  }\n  \n  .button_product1{\n    background-color:#7A2031 !important;\n    border-radius: 0 !important;\n    border: none !important;\n    color: #DAA9CF !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
