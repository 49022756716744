import  React from 'react';
import Box from '@mui/material/Box';
import './Offer.css'

function Offer() {
 

  return (
    <Box className='offer' >
        <Box className='offer_name' >ПУБЛИЧНАЯ ОФЕРТА</Box>
            <Box className='offer_info_1_text'> 
            <p>Настоящий документ – это публичная оферта (предложение) интернет-магазина coucou.com о продаже товаров.</p>
            <p className='offer_name_main'>1. Общие положения</p>
            <p>1.1. Настоящая публичная оферта (далее - Оферта) является официальным предложением ИП Войтюка Никиты Валерьевича в адрес любого физического лица заключить с ИП Войтюк Никита Валерьевич договор розничной купли-продажи товара на Сайте дистанционным образом на условиях, определенных в настоящем Договоре и содержит все существенные условия Оферты.</p>
            <p>1.2. Заказ Покупателем товара, размещенного на Сайте, означает, что Покупатель согласен со всеми условиями настоящей Оферты, Политики конфиденциальности и Пользовательского соглашения.</p>
            <p>1.3. Сайт имеет право вносить изменения в Оферту без уведомления Покупателя.</p>
            <p>1.4. Срок действия Оферты не ограничен, если иное не указано на Сайте.</p>
            <p>1.5. Сайт предоставляет Покупателю полную и достоверную информацию о товаре/услугах, включая информацию об основных потребительских свойствах товара, месте изготовления, а также информацию о гарантийном сроке и сроке годности товара на Сайте в карточке товара.</p>
            <p className='offer_name_main'>2. Предмет Оферты</p>
            <p>2.1. ИП Войтюк Никита Валерьевич обязуется передать Покупателю товар, предназначенный для личного, семейного, домашнего или иного использования, не связанного с предпринимательской деятельностью, на основании размещенных Заказов, а Покупатель обязуется принять и оплатить Товар на условиях настоящей Оферты.</p>
            <p>2.2. Наименование, цена, количество товара, а также прочие необходимые условия Оферты определяются на основании сведений, предоставленных Покупателем при оформлении заказа.</p>
            <p>2.3. Право собственности на заказанные товары переходит к Покупателю с момента фактической передачи товара Покупателю и оплаты последним полной стоимости товара. Риск его случайной гибели или повреждения товара переходит к Покупателю с момента фактической передачи товара Покупателю.</p>
            <p className='offer_name_main'>3. Стоимость товара/услуг</p>
            <p>3.1. Цены на товар определяются Продавцом в одностороннем бесспорном порядке и указываются на страницах интернет-магазина, расположенного по интернет-адресу coucou.com.</p>
            <p>3.2. Цена товара указывается в рублях Российской Федерации и не включает в себя налог на добавленную стоимость.</p>
            <p>3.3. Окончательная цена товара определяется последовательным действием на цену товара скидок по следующему порядку:
                - Акционная скидка
                - Скидка по промо-коду
                - Скидка постоянного Покупателя
                </p>
            <p>3.4. Расчеты между Сайтом и Покупателем за товар производятся способами, указанными на Сайте в разделе – Доставка и Оплата</p>
            <p className='offer_name_main'>4. Момент заключения Оферты</p>
            <p>4.1. Акцептируя настоящую Оферту, Покупатель выражает согласие в том, что:
                <p>- регистрационные данные (в том числе персональные данные и данные переданные с использованием сервиса Сбер ID (функционал ПАО «Сбербанк России» по передаче персональных данных Пользователя, предоставляемый в целях аутентификации Пользователя и автозаполнения данных о Пользователе на Сайте)) указаны им добровольно;</p>
                <p>- регистрационные данные (в том числе персональные данные и данные переданные с использованием сервиса Сбер ID (функционал ПАО «Сбербанк России» по передаче персональных данных Пользователя, предоставляемый в целях аутентификации Пользователя и автозаполнения данных о Пользователе на Сайте)) передаются в электронной форме по каналам связи сети "интернет";</p>
                <p>- регистрационные данные (в том числе персональные данные и данные переданные с использованием сервиса Сбер ID (функционал ПАО «Сбербанк России» по передаче персональных данных Пользователя, предоставляемый в целях аутентификации Пользователя и автозаполнения данных о Пользователе на Сайте)) переданы Сайту для реализации целей, указанных в настоящей Оферте, Политике конфиденциальности, Пользовательском соглашении и могут быть переданы третьим лицам, для реализации целей, указанных в настоящей Оферте;</p>
                <p>- регистрационные данные (в том числе персональные данные и данные переданные с использованием сервиса Сбер ID (функционал ПАО «Сбербанк России» по передаче персональных данных Пользователя, предоставляемый в целях аутентификации Пользователя и автозаполнения данных о Пользователе на Сайте)) могут быть использованы Сайтом в целях продвижения товаров и услуг, путем осуществления прямых контактов с Покупателем с помощью каналов связи;</p>
                <p>- в целях дополнительной защиты от мошеннических действий указанные Покупателем регистрационные данные (в том числе персональные данные и данные переданные с использованием сервиса Сбер ID (функционал ПАО «Сбербанк России» по передаче персональных данных Пользователя, предоставляемый в целях аутентификации Пользователя и автозаполнения данных о Пользователе на Сайте)) могут быть переданы банку, осуществляющему транзакции по оплате оформленных заказов;</p>
                <p>- данное Покупателем согласие на обработку его регистрационных данных (в том числе персональные данные и данные переданные с использованием сервиса Сбер ID (функционал ПАО «Сбербанк России» по передаче персональных данных Пользователя, предоставляемый в целях аутентификации Пользователя и автозаполнения данных о Пользователе на Сайте)) является бессрочным и может быть отозвано Покупателем или его законным представителем, подачей письменного заявления, переданного Сайту.</p>
                </p>
            <p className='offer_name_main'>5. Возврат товара и денежных средств</p>
            <p>5.1. Возврат товара осуществляется в соответствии с Законом РФ "О защите прав потребителей".</p>
            <p>5.2. Возврат денежных средств осуществляется посредством возврата стоимости оплаченного товара на банковскую карту или почтовым переводом.</p>
            <p className='offer_name_main'>6.  Доставка товара</p>
            <p>6.1. Доставка товара Покупателю осуществляется в сроки, согласованные Сторонами при подтверждении заказа сотрудником Сайта.</p>
            <p>6.2. При курьерской доставке товара Покупатель в реестре доставки ставит свою подпись напротив тех позиций товара, которые Покупатель приобрел. Данная подпись служит подтверждением того, что Покупатель не имеет претензий к комплектации товара, к количеству и внешнему виду товара.</p>
            <p>6.3. После получения товара претензии к количеству, комплектности и виду товара не принимаются.</p>
            <p className='offer_name_main'>7. Срок действия Оферты</p>
            <p>7.1. Настоящая Оферта вступает в силу с момента ее акцепта Покупателем, и действует до момента отзыва акцепта публичной Оферты.</p>
            <p className='offer_name_main'>8. Дополнительные условия</p>
            <p>8.1. Сайт вправе переуступать либо каким-либо иным способом передавать свои права и обязанности, вытекающие из его отношений с Покупателем, третьим лицам.</p>
            <p>8.2. Сайт и предоставляемые сервисы могут временно частично или полностью недоступны по причине проведения профилактических или иных работ, или по любым другим причинам технического характера. Техническая служба Сайта имеет право периодически проводить необходимые профилактические или иные работы с предварительным уведомлением Покупателей или без такового.</p>
            <p>8.3. К отношениям между Покупателем и Сайтом применяются положения Российского законодательства.</p>
            <p>8.4. В случае возникновения вопросов и претензий со стороны Покупателя он должен обратиться к Сайту по телефону или иным доступным способом. Все возникающее споры стороны будут стараться решить путем переговоров, при недостижении соглашения спор будет передан на рассмотрение в судебный орган в соответствии с действующим законодательством РФ.</p>
            <p>8.5. Признание судом недействительности какого-либо положения настоящего Соглашения не влечет за собой недействительность остальных положений.</p>
            <p className='offer_name_main'>9. Реквизиты Сайта</p>
            <p>ИП Войтюк Никита Валерьевич</p>
            <p>ИНН: 390704910690</p>
            <p>ОГРН: 324390000012976</p>

            </Box>
    </Box>
);
}

export default Offer;
