// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.refund_info_1_name,
.refund_info_2_name,
.refund_info_3_name,
.refund_info_4_name,
.refund_info_5_name,
.refund_name{
    color: #7A2031
}
.refund_name{
    font-size: 2rem;
}

.refund{
    padding: 9rem 2rem 2rem 3rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.refund_info{
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.refund_info_1_text,
.refund_info_2_text,
.refund_info_3_text,
.refund_info_4_text,
.refund_info_5_text{
    padding-left: 1rem;
    padding-top: 1rem;
    padding-right: 1rem;
}
.refund_info_1_text p,
.refund_info_3_text p,
.refund_info_4_text p,
.refund_info_5_text p ,
.refund_info_2_text {
    margin-bottom: 0px;
    text-align: justify;
}

@media (min-width: 768px) {
    .refund_info_1_text p,
    .refund_info_3_text p,
    .refund_info_4_text p,
    .refund_info_5_text p,
    .first_refund_info, 
    .refund_info_2_text {
        font-size: 18px;
    }

  }`, "",{"version":3,"sources":["webpack://./src/Refund/Refund.css"],"names":[],"mappings":"AAAA;;;;;;IAMI;AACJ;AACA;IACI,eAAe;AACnB;;AAEA;IACI,4BAA4B;IAC5B,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;;;;;IAKI,kBAAkB;IAClB,iBAAiB;IACjB,mBAAmB;AACvB;AACA;;;;;IAKI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI;;;;;;QAMI,eAAe;IACnB;;EAEF","sourcesContent":[".refund_info_1_name,\n.refund_info_2_name,\n.refund_info_3_name,\n.refund_info_4_name,\n.refund_info_5_name,\n.refund_name{\n    color: #7A2031\n}\n.refund_name{\n    font-size: 2rem;\n}\n\n.refund{\n    padding: 9rem 2rem 2rem 3rem;\n    display: flex;\n    flex-direction: column;\n    gap: 3rem;\n}\n\n.refund_info{\n    display: flex;\n    flex-direction: column;\n    gap: 3rem;\n}\n\n.refund_info_1_text,\n.refund_info_2_text,\n.refund_info_3_text,\n.refund_info_4_text,\n.refund_info_5_text{\n    padding-left: 1rem;\n    padding-top: 1rem;\n    padding-right: 1rem;\n}\n.refund_info_1_text p,\n.refund_info_3_text p,\n.refund_info_4_text p,\n.refund_info_5_text p ,\n.refund_info_2_text {\n    margin-bottom: 0px;\n    text-align: justify;\n}\n\n@media (min-width: 768px) {\n    .refund_info_1_text p,\n    .refund_info_3_text p,\n    .refund_info_4_text p,\n    .refund_info_5_text p,\n    .first_refund_info, \n    .refund_info_2_text {\n        font-size: 18px;\n    }\n\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
