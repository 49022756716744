// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/Forum-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    margin: 0;
    padding: 0;
  }
@font-face {
    font-family: 'Forum';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');;
    font-weight: normal;
    font-style: normal;
  }
  
  body {
    font-family: 'Forum', sans-serif;
    height: 100%;
    width: 100%;
  }

  .header{
    font-family: 'Forum', sans-serif;
  }
  `, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;EACZ;AACF;IACI,oBAAoB;IACpB,+DAAwD;IACxD,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,gCAAgC;IAChC,YAAY;IACZ,WAAW;EACb;;EAEA;IACE,gCAAgC;EAClC","sourcesContent":["* {\n    margin: 0;\n    padding: 0;\n  }\n@font-face {\n    font-family: 'Forum';\n    src: url('./fonts/Forum-Regular.ttf') format('truetype');;\n    font-weight: normal;\n    font-style: normal;\n  }\n  \n  body {\n    font-family: 'Forum', sans-serif;\n    height: 100%;\n    width: 100%;\n  }\n\n  .header{\n    font-family: 'Forum', sans-serif;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
