// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contacts_info_1_name,
.contacts_info_2_name,
.contacts_info_3_name,
.contacts_info_4_name,
.contacts_info_5_name,
.contacts_name{
    color: #7A2031
}
.contacts_name{
    font-size: 2rem;
}

.contacts{
    padding: 9rem 2rem 2rem 3rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.contacts_info{
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.contacts_info_1_text,
.contacts_info_2_text,
.contacts_info_3_text,
.contacts_info_4_text,
.contacts_info_5_text{
    padding-left: 1rem;
    padding-top: 1rem;
    padding-right: 1rem;
}
.contacts_info_1_text,
.contacts_info_3_text p,
.contacts_info_4_text,
.contacts_info_5_text p {
    margin-bottom: 0px;
}

@media (min-width: 768px) {
    .contacts_info_1_text,
    .contacts_info_3_text p,
    .contacts_info_4_text,
    .contacts_info_5_text p,
    .contacts_info_2_1_text {
       font-size: 18px;
    }

  }`, "",{"version":3,"sources":["webpack://./src/Contacts/Contacts.css"],"names":[],"mappings":"AAAA;;;;;;IAMI;AACJ;AACA;IACI,eAAe;AACnB;;AAEA;IACI,4BAA4B;IAC5B,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;;;;;IAKI,kBAAkB;IAClB,iBAAiB;IACjB,mBAAmB;AACvB;AACA;;;;IAII,kBAAkB;AACtB;;AAEA;IACI;;;;;OAKG,eAAe;IAClB;;EAEF","sourcesContent":[".contacts_info_1_name,\n.contacts_info_2_name,\n.contacts_info_3_name,\n.contacts_info_4_name,\n.contacts_info_5_name,\n.contacts_name{\n    color: #7A2031\n}\n.contacts_name{\n    font-size: 2rem;\n}\n\n.contacts{\n    padding: 9rem 2rem 2rem 3rem;\n    display: flex;\n    flex-direction: column;\n    gap: 3rem;\n}\n\n.contacts_info{\n    display: flex;\n    flex-direction: column;\n    gap: 3rem;\n}\n\n.contacts_info_1_text,\n.contacts_info_2_text,\n.contacts_info_3_text,\n.contacts_info_4_text,\n.contacts_info_5_text{\n    padding-left: 1rem;\n    padding-top: 1rem;\n    padding-right: 1rem;\n}\n.contacts_info_1_text,\n.contacts_info_3_text p,\n.contacts_info_4_text,\n.contacts_info_5_text p {\n    margin-bottom: 0px;\n}\n\n@media (min-width: 768px) {\n    .contacts_info_1_text,\n    .contacts_info_3_text p,\n    .contacts_info_4_text,\n    .contacts_info_5_text p,\n    .contacts_info_2_1_text {\n       font-size: 18px;\n    }\n\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
