// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.brand_name {
    color: #7A2031;
    font-size: 2rem;
}

.brand {
    padding: 9rem 2rem 2rem 3rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.brand_info_1_text {
    padding: 1rem;
}

.brand_info_1_text p {
    margin-bottom: 1rem;
    text-align: justify;
    position: relative;
}
.brand_info_1_text p:nth-of-type(3) {
    text-align: left; 
}
.brand_info_1_text p::after {
    content: '';
    display: inline-block;
    width: 100%;
}

.highlighted-text {
    color: #7A2031;
}

.brand_name_img {
    display: flex;
    justify-content: center;
}

@media (min-width: 768px) {
    .brand_info_1_text p {
        font-size: 18px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/Brand/Brand.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,4BAA4B;IAC5B,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,mBAAmB;IACnB,mBAAmB;IACnB,kBAAkB;AACtB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,WAAW;IACX,qBAAqB;IACrB,WAAW;AACf;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":[".brand_name {\n    color: #7A2031;\n    font-size: 2rem;\n}\n\n.brand {\n    padding: 9rem 2rem 2rem 3rem;\n    display: flex;\n    flex-direction: column;\n    gap: 3rem;\n}\n\n.brand_info_1_text {\n    padding: 1rem;\n}\n\n.brand_info_1_text p {\n    margin-bottom: 1rem;\n    text-align: justify;\n    position: relative;\n}\n.brand_info_1_text p:nth-of-type(3) {\n    text-align: left; \n}\n.brand_info_1_text p::after {\n    content: '';\n    display: inline-block;\n    width: 100%;\n}\n\n.highlighted-text {\n    color: #7A2031;\n}\n\n.brand_name_img {\n    display: flex;\n    justify-content: center;\n}\n\n@media (min-width: 768px) {\n    .brand_info_1_text p {\n        font-size: 18px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
